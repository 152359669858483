import "../styles/globals.css";
import Head from "next/head";
import { ReactNode } from "react";
import { AppContext, AppInitialProps, AppLayoutProps } from "next/app";
import type { NextComponentType } from "next";
import { Inter } from "next/font/google";
import { App } from "konsta/react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

// If loading a variable font, you don't need to specify the font weight
const inter = Inter({ subsets: ["latin"] });
const AnalyticsService = import("../src/modules/analytics/services").then(
  (pkg) => pkg.default
);

if (typeof window !== "undefined") {
  const localUser = JSON.parse(window.localStorage.getItem("user") || "{}");

  AnalyticsService.then((service) => service.init(localUser?.id));
}

const MyApp: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
  Component,
  pageProps,
}: AppLayoutProps) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);
  const router = useRouter();
  const AppProviders = dynamic(() => import("../src/components/AppProviders"));

  let jsx = (
    <div className={"h-screen " + inter.className}>
      {getLayout(<Component {...pageProps} />)}
    </div>
  );

  let renderAppProviders = true;

  if (["/", "/about", "/contact"].includes(router.pathname)) {
    renderAppProviders = false;
  }

  return (
    <App theme="material" color="light">
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      {renderAppProviders ? <AppProviders>{jsx}</AppProviders> : <>{jsx}</>}
    </App>
  );
};

export default MyApp;
